body {
	font-family: "Open Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	position:relative;
	min-height:100vh;
	//padding-bottom: 65px;
}

$header_color: #37bbcc;
$header_hover: #2fa2b1;
$nav_accent: #2a97a5;
$service-btns: #2a97a5;
$service-btns-hover: #227f8b;

h2,
h3 {
	color: $header_color;
	text-transform: uppercase;
	font-size: 24px;
	line-height: 35px;
}

p {
	color: #7b7b7b;
}

#image-grid {
	padding: 100px 0;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #dfdfdf;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	.block {
		h2 {
			padding-top: 35px;
			line-height: 35px;
			margin: 0;
		}
		p {
			color: #000000;
			padding-top: 20px;
		}
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 80%;
		}
	}
	.section-title {
		margin-bottom: 0px;
		p {
			padding-top: 20px;
		}
	}
}
#service-blurb {
	background-position: 50% 94px;
	width: 100%;
	display: block;
	position: relative;
	overflow: visible;
	
	padding: 100px 0;
	h2 {
		font-size: 28px;
		font-weight: 600;
	}
	p {
		margin-bottom: 20px;
	}
	.btn-view-works {
		background: #655e7a;
		color: #fff;
		padding: 10px 20px;
	}
}

a.btn {
	&.btn-styled-brand-teal {
		background: $service-btns;
		color: #fff;
		padding: 10px 20px;
		margin-bottom: 30px;
		&:hover,
		&:focus {
			color: #fff;
			background: $service-btns-hover;
		}
		&.services-back {
			margin-top:30px;
		}
	}
}

#slider {
	background-image: image-set(
		url("/assets/img/banners/slider-bg.webp") type("image/webp"),
		url("/assets/img/banners/slider-bg.jpg") type("image/jpeg")
	);
	background-image: -webkit-image-set(
		url("/assets/img/banners/slider-bg.webp") type("image/webp"),
		url("/assets/img/banners/slider-bg.jpg") type("image/jpeg")
	);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: 10% 0%;
	padding: 100px 0 140px 0;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to left, #8b86a3, #322e40);
		opacity: 0.8;
	}
	.block {
		color: #e3e3e4;
		h1 {
			font-family: "Roboto", sans-serif;
			font-weight: 700;
			font-size: 45px;
			line-height: 60px;
			letter-spacing: 4px;
			padding-bottom: 45px;
		}
		p {
			color: #e3e3e4;
			font-size: 23px;
			line-height: 40px;
			font-family: "Roboto", sans-serif;
			font-weight: 300;
			letter-spacing: 3px;
		}
	}
}

p {
	font-size: 17px;
}

.section-title {
	p {
		font-family: "Volkhov", serif;
		font-style: italic;
		color: #000;
	}
}

a {
	color: inherit;
}
.header-img-container {
	img.header-img {
		// overflow:hidden;
		object-fit: cover;
		width: 100vw;
		height: 100%;
		object-position: 0px bottom;
	}
	display: block;
	
	height: 50vh;
	margin: 0px 0px 10px;
	overflow: hidden;
}

#slider {
	.block {
		h1 {
			text-transform: uppercase;
		}
	}
}

ul#nav > li {
	border: none;
	a {
		&.active,
		&:hover {
			color: $nav_accent;
		}
	}
}

.h-divider {
	margin-top: 10px;
	margin-bottom: 15px;
	height: 1px;
	width: 100%;
	border-top: 3px solid gray;
	
	&.blue {
		border-color: $header_color;
	}
	&.thin {
		border-width: 1px;
	}
}

img {
	&.padded-img {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

form {
	%text-input {
		width: 100%;
		padding: 12px 20px;
		margin: 8px 0 8px 0;
		display: inline-block;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-sizing: border-box;
		word-break: break-all;
	}
	
	textarea {
		@extend %text-input;
		height: 150px;
		resize: vertical;
	}
	input {
		&[type="text"],
		&[type="email"] {
			@extend %text-input;
		}
		&[type="submit"] {
			&:hover {
				background-color: $header_hover;
			}
			width: 100%;
			background-color: $header_color;
			color: white;
			padding: 14px 20px;
			margin: 8px 0;
			border: none;
			border-radius: 4px;
			cursor: pointer;
		}
	}
	label {
		margin-top: 20px;
		margin-bottom: 5px;
	}
}

p.siteSubtitle {
	max-width: 800px;
}

section {
	margin-top: 2vh;
	&#services {
		border-top: 4px solid #2a97a5;
		.title-section p {
			margin-bottom: 40px;
		}
		.section-title {
			margin-top:5vh;
		}
		.row {
			padding-bottom:10px;
		}
		h3 {
			margin-top:5px;
		}
	}
}

.navbar-brand {
	width: 150px;
	font-size: 24px;
	
	img {
		height: 50px;
		float: left;
		margin-right: 7px;
	}
}

.padded-section-title {
	@extend .section-title;
	margin-top: 30px;
}

.padded {
	margin-top:30px;
	margin-bottom:30px;
}

footer {
	position:absolute;
	bottom:0;
	left:0;
	right:0;
	padding-top:20px;
	padding-bottom:10px;
	//margin-top: 30px;
}

h2 {
	text-transform: uppercase;
	font-size: 28px;
	font-weight: 600;
}